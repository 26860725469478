@import '../../../less/lib/spry-variables.less';
@import 'variables.less';

@bs-datetimepicker-timepicker-font-size: 1.4em;
@bs-datetimepicker-active-bg: @btn-primary-bg;
@bs-datetimepicker-active-color: @btn-primary-color;
@bs-datetimepicker-border-radius: 0;//@border-radius-base;
@bs-datetimepicker-btn-hover-bg: @gray-lighter;
@bs-datetimepicker-disabled-color: hsl(hue(@gray-light), saturation(@gray-light), 100%);
@bs-datetimepicker-alternate-color: @gray-light;
@bs-datetimepicker-secondary-border-color: #ccc;
@bs-datetimepicker-secondary-border-color-rgba: rgba(0, 0, 0, 0.2);
@bs-datetimepicker-primary-border-color: white;
@bs-datetimepicker-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

.sr-only, .bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after, .bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after, .bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after, .bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after, .bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after, .bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after, .bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after, .bootstrap-datetimepicker-widget .btn[data-action="clear"]::after, .bootstrap-datetimepicker-widget .btn[data-action="today"]::after, .bootstrap-datetimepicker-widget .picker-switch::after, .bootstrap-datetimepicker-widget table th.prev::after, .bootstrap-datetimepicker-widget table th.next::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.bootstrap-datetimepicker-widget {
  list-style: none; }
  .bootstrap-datetimepicker-widget.dropdown-menu {
    display: block;
    margin: 2px 0;
    padding: .4em;
    width: 14rem; }
    @media (min-width: 576px) {
      .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
        width: 38em; } }
    @media (min-width: 768px) {
      .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
        width: 38em; } }
    @media (min-width: 992px) {
      .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
        width: 38em; } }
    .bootstrap-datetimepicker-widget.dropdown-menu:before, .bootstrap-datetimepicker-widget.dropdown-menu:after {
      content: '';
      display: inline-block;
      position: absolute; }
    .bootstrap-datetimepicker-widget.dropdown-menu.bottom:before {
      border-left: .7em solid transparent;
      border-right: .7em solid transparent;
      border-bottom: .7em solid @bs-datetimepicker-secondary-border-color;
      border-bottom-color: @bs-datetimepicker-secondary-border-color-rgba;
      top: -.7em;
      left: .64em; }
    .bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
      border-left: .6em solid transparent;
      border-right: .6em solid transparent;
      border-bottom: .6em solid @bs-datetimepicker-primary-border-color;
      top: -.55em;
      left: .74em; }
    .bootstrap-datetimepicker-widget.dropdown-menu.top:before {
      border-left: .7em solid transparent;
      border-right: .7em solid transparent;
      border-top: .7em solid @bs-datetimepicker-secondary-border-color;
      border-top-color: @bs-datetimepicker-secondary-border-color-rgba;
      bottom: -.7em;
      left: .7em; }
    .bootstrap-datetimepicker-widget.dropdown-menu.top:after {
      border-left: .6em solid transparent;
      border-right: .6em solid transparent;
      border-top: .6em solid @bs-datetimepicker-primary-border-color;
      bottom: -.6em;
      left: .7em; }
    .bootstrap-datetimepicker-widget.dropdown-menu.float-right:before {
      left: auto;
      right: .6em; }
    .bootstrap-datetimepicker-widget.dropdown-menu.float-right:after {
      left: auto;
      right: .7em; }
    .bootstrap-datetimepicker-widget.dropdown-menu.wider {
      width: 16rem; }
  .bootstrap-datetimepicker-widget .list-unstyled {
    margin: 0; }
  .bootstrap-datetimepicker-widget a[data-action] {
    padding: .6em 0; }
  .bootstrap-datetimepicker-widget a[data-action]:active {
    box-shadow: none; }
  .bootstrap-datetimepicker-widget .timepicker {
    td, th {
      padding: 0;
    }
    .separator {
      font-weight: bold;
    }
    .separator, .btn-primary {
      position: relative;
      top: -.2em;
    }
  }
  .bootstrap-datetimepicker-widget .timepicker-hour, .bootstrap-datetimepicker-widget .timepicker-minute, .bootstrap-datetimepicker-widget .timepicker-second {
    width: 3.4em;
    font-weight: bold;
    font-size: @bs-datetimepicker-timepicker-font-size;
    margin: 0; }
  .bootstrap-datetimepicker-widget button[data-action] {
    padding: .6em; }
  .bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after {
    content: "Increment Hours"; }
  .bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after {
    content: "Increment Minutes"; }
  .bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after {
    content: "Decrement Hours"; }
  .bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after {
    content: "Decrement Minutes"; }
  .bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after {
    content: "Show Hours"; }
  .bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after {
    content: "Show Minutes"; }
  .bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after {
    content: "Toggle AM/PM"; }
  .bootstrap-datetimepicker-widget .btn[data-action="clear"]::after {
    content: "Clear the picker"; }
  .bootstrap-datetimepicker-widget .btn[data-action="today"]::after {
    content: "Set the date to today"; }
  .bootstrap-datetimepicker-widget .picker-switch {
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;
    color: @heading-color; }
    .bootstrap-datetimepicker-widget .picker-switch::after {
      content: "Toggle Date and Time Screens"; }
    .bootstrap-datetimepicker-widget .picker-switch td {
      padding: 0;
      margin: 0;
      height: auto;
      width: auto;
      line-height: inherit; }
      .bootstrap-datetimepicker-widget .picker-switch td span {
        line-height: 2.5;
        height: 2.5em;
        width: 100%; }
  .bootstrap-datetimepicker-widget table {
    width: 100%;
    margin: 0; }
    .bootstrap-datetimepicker-widget table td,
    .bootstrap-datetimepicker-widget table th {
      text-align: center;
      border-radius: 2em;
      padding: .7em 0; }
    .bootstrap-datetimepicker-widget table th {
      height: 1.5em;
      line-height: 1.5em;
      width: 2.8em; }
      .bootstrap-datetimepicker-widget table th.picker-switch {
        width: 145px; }
      .bootstrap-datetimepicker-widget table th.disabled, .bootstrap-datetimepicker-widget table th.disabled:hover {
        background: none;
        color: @bs-datetimepicker-disabled-color;
        cursor: not-allowed; }
      .bootstrap-datetimepicker-widget table th.prev::after {
        content: "Previous Month"; }
      .bootstrap-datetimepicker-widget table th.next::after {
        content: "Next Month"; }
    .bootstrap-datetimepicker-widget table thead tr:first-child th {
      cursor: pointer; }
      .bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
        background: @bs-datetimepicker-btn-hover-bg; }
    .bootstrap-datetimepicker-widget table td {
      height: 2.8em;
      line-height: 2.8em;
      width: 2.8em; }
      .bootstrap-datetimepicker-widget table td.cw {
        height: 1.5em;
        line-height: 1.5em;
        color: @bs-datetimepicker-alternate-color;
        font-weight: 500; }
      .bootstrap-datetimepicker-widget table td.day {
        height: 1.5em;
        line-height: 1.5em;
        width: 2.8em; }
      .bootstrap-datetimepicker-widget table td.day:hover, .bootstrap-datetimepicker-widget table td.hour:hover, .bootstrap-datetimepicker-widget table td.minute:hover, .bootstrap-datetimepicker-widget table td.second:hover {
        background: @bs-datetimepicker-btn-hover-bg;
        cursor: pointer; }
      .bootstrap-datetimepicker-widget table td.old, .bootstrap-datetimepicker-widget table td.new {
        color: @text-color-very-light;
        font-weight: 300; }
      .bootstrap-datetimepicker-widget table td.today {
        position: relative; }
        .bootstrap-datetimepicker-widget table td.today:before {
          content: '';
          display: block;
          border: 1px solid fade(@attention, 30%);
          border-radius: 2em;
          margin: .35em;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0; }
      .bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
        background-color: @complimentary-background-color;
        color: @bs-datetimepicker-active-color;
        text-shadow: @bs-datetimepicker-text-shadow; }
      .bootstrap-datetimepicker-widget table td.active.today:before {
        border-color: fade(@background-white, 30%); }
      .bootstrap-datetimepicker-widget table td.disabled, .bootstrap-datetimepicker-widget table td.disabled:hover {
        background: none;
        color: @bs-datetimepicker-disabled-color;
        cursor: not-allowed; }
      .bootstrap-datetimepicker-widget table td span {
        display: inline-block;
        width: 3.4em;
        height: 1.5em;
        line-height: 1.5em;
        margin: .2em 1.5px;
        cursor: pointer; }
        .bootstrap-datetimepicker-widget table td span:hover {
          background: @bs-datetimepicker-btn-hover-bg; }
        .bootstrap-datetimepicker-widget table td span.active {
          background-color: @bs-datetimepicker-active-bg;
          color: @bs-datetimepicker-active-color;
          text-shadow: @bs-datetimepicker-text-shadow; }
        .bootstrap-datetimepicker-widget table td span.old {
          color: @bs-datetimepicker-alternate-color; }
        .bootstrap-datetimepicker-widget table td span.disabled, .bootstrap-datetimepicker-widget table td span.disabled:hover {
          background: none;
          color: @bs-datetimepicker-disabled-color;
          cursor: not-allowed; }
  .bootstrap-datetimepicker-widget.usetwentyfour td.hour {
    height: 27px;
    line-height: 27px; }

.input-group [data-toggle="datetimepicker"] {
  cursor: pointer; }

.bootstrap-datetimepicker-widget .datepicker-months tbody td,
.bootstrap-datetimepicker-widget .datepicker-years tbody td {
    padding-left: 1em;
    padding-right: 1em;
}
